import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <p>{`I have previously studied astrophysics, GIS and a little Java, and worked as a counselor for young people with autism for many years. Now I have finally found my calling and switched paths to become a front end developer. In my free time I like to hang out with my girlfriend, cook, play board games, watch movies and hang out with friends.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      