import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Links`}</h2>
    <ProjectCard title="GitHub" link="https://github.com/Fredrikwide?tab=repositories" bg="linear-gradient(to right, #000000 0%, #fefefe 100%)" mdxType="ProjectCard">
This is my GitHub repositories page. It is not very impressive... yet!
    </ProjectCard>
    <ProjectCard title="LinkedIn" link="https://www.linkedin.com/in/fredrik-widegren-b5044413b/" bg="linear-gradient(to right, #315ba7 0%, #283e4a 100%)" mdxType="ProjectCard">
And this is my LinkedIn profile. I have not spent many hours on it... yet!
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      