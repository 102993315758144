import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Fredrik Widegren`}</h1>
    <h5>{`I'm studying Front End Development @ Medieinstitutet in Malmö, Sweden.`}</h5>
    <h6>{`JavaScript | React | Node | Express | Git | And more.`}</h6>
    <p>{`Check out my `}<a parentName="p" {...{
        "href": "https://github.com/Fredrikwide?tab=repositories"
      }}>{`GitHub`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/fredrik-widegren-b5044413b/"
      }}>{`LinkedIn`}</a>{` `}</p>
    <p>{`Send me an email @ `}<a parentName="p" {...{
        "href": "mailto:Fredrik.widegren@gmail.com"
      }}>{`Fredrik.widegren@gmail.com`}</a>{` and we'll talk! `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      